.title {
    font-size: 16px;
}

.authors {
    font-size: 15px !important;
}

.venue {
    font-size: 14px;
}