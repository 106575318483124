.navbarText {
    font-size: 1rem!important;
    font-family: 'Overpass', sans-serif;
}

.navbarColors {
    background-color: var(--red-cabin);
    color: var(--snow-background);
}

.className {
    font-weight: bolder!important;
    color: var(--snow-background)!important;
}
.sticky {
    position: sticky!important;
    top: 0;
    z-index: 40;
    bottom: 10px;
}
@media (min-width:576px) {

}

@media (min-width:768px) {

}
/* Because the app is weird! */
@media (min-width:930px) {
    .navbarText {
        font-size: 1.1rem!important;
    }
}

@media (min-width:992px) {
    .navbarText {
        font-size: 1.3rem!important;
    }
}

@media (min-width:1200px) {
    /* .navbarText {
        font-size: 1.5rem!important;
    } */
}
